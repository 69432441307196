import React, { useEffect, useState, useCallback } from "react";
import styled from "styled-components";

import Layout from "@common/Layout";
import { TopHeader } from "@common/TopHeader";
import { Final } from "@common/Final";
import { navigate, graphql } from "gatsby";

import Footer from "@sections/Footer";
import { Container } from "@components/global";

const ReservationFinishPage = ({ data, location }) => {
  return (
    <Layout>
      <TopHeader />
      <Container>
        <Final data={data} location={location} />
      </Container>
      <Footer />
    </Layout>
  );
};

export const query = graphql`
  query ReservationFinishPageQuery {
    site {
      siteMetadata {
        options {
          name
          price
          multiplyByDays
        }
        offices {
          name
          workingHoursMonFri {
            start
            end
          }
          workingHoursSaturday {
            start
            end
          }
          deliveryFee
          returnFee
          deliveryFeeOutsideWorkingHours
          returnFeeOutsideWorkingHours
        }
      }
    }
    allImageSharp {
      edges {
        node {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
`;

export default ReservationFinishPage;
