import React, { useEffect, useState, useCallback } from "react";
import styled from "styled-components";

import StandaloneCarCard from "@common/StandaloneCarCard";
import { calculateRental } from "./../../../calc";
import { MapPin } from "styled-icons/boxicons-solid";

import { Breadcrumbs } from "@common/Breadcrumbs";
import { Button, Checkbox, FormControlLabel } from "@material-ui/core";
import countries from "./../../../countries";
import {
  StyledSelect,
  StyledTextField,
  StyledFormControlLabel,
  StyledCheckbox,
  CheckboxLabel,
  Label,
} from "@common/Form";

import CircularProgress from "@material-ui/core/CircularProgress";

import { DateTime } from "luxon";

function getPlMonth(date) {
  let mon = "";
  if (date.month === 1) {
    mon = "sty";
  } else if (date.month === 2) {
    mon = "lut";
  } else if (date.month === 3) {
    mon = "mar";
  } else if (date.month === 4) {
    mon = "kwi";
  } else if (date.month === 5) {
    mon = "maj";
  } else if (date.month === 6) {
    mon = "cze";
  } else if (date.month === 7) {
    mon = "lip";
  } else if (date.month === 8) {
    mon = "sie";
  } else if (date.month === 9) {
    mon = "wrz";
  } else if (date.month === 10) {
    mon = "paź";
  } else if (date.month === 11) {
    mon = "lis";
  } else if (date.month === 12) {
    mon = "gru";
  }
  return mon;
}

function formatDate(date) {
  const datetime = DateTime.fromMillis(date);
  return `${datetime.toFormat("d")} ${getPlMonth(datetime)} ${datetime.toFormat(
    "yyyy H:mm"
  )}`;
}

const NextButton = styled(({ className, ...rest }) => {
  return (
    <div className={className}>
      <Button {...rest} />
    </div>
  );
})`
  text-align: center;
  margin-top: 1rem;
`;

export const Header = styled.h1`
  font-family: ${(props) => props.theme.font.primary};
  ${(props) => props.theme.font_size.larger};
  margin: 1rem 0;
`;

export const ThankYou = styled.div`
  font-family: ${(props) => props.theme.font.secondary};
  ${(props) => props.theme.font_size.large};
  text-align: center;
  margin: 4rem 0;
`;

export const Options = styled.div`
  grid-area: options;
  margin-bottom: 2.5rem;
  p {
    ${(props) => props.theme.font_size.small};
    color: ${(props) => props.theme.color.black.light};
    margin-bottom: 1rem;
  }
`;

export const CarCard = styled.div`
  grid-area: carCard;
`;

export const OptionCheckbox = styled(Checkbox)``;

export const Wrapper = styled.div`
  display: grid;
  grid-template-areas:
    "options"
    "carCard";
  @media (min-width: 850px) {
    grid-template-areas: "options carCard";
    align-items: flex-start;
    justify-items: center;
    grid-gap: 2rem;
  }
  margin-bottom: 3rem;
`;

export const TwoGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  @media (min-width: ${(props) => props.theme.screen.sm}) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 12px;
  }
`;

export const Error = styled.div`
  color: red;
`;

export const Final = ({ data, location }) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [street, setStreet] = useState("");
  const [house, setHouse] = useState("");
  const [postal, setPostal] = useState("");
  const [city, setCity] = useState("");
  const [prawoJazdy, setPrawoJazdy] = useState("");
  const [dowod, setDowod] = useState("");
  const [pesel, setPesel] = useState("");
  const [parentNames, setParentNames] = useState("");
  const [country, setCountry] = useState("Polska");

  const [needsInvoice, setNeedsInvoice] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [taxNo, setTaxNo] = useState("");
  const [invoiceStreet, setInvoiceStreet] = useState("");
  const [invoiceHouse, setInvoiceHouse] = useState("");
  const [invoicePostal, setInvoicePostal] = useState("");
  const [invoiceCity, setInvoiceCity] = useState("");

  const [comments, setComments] = useState("");

  const [error, setError] = useState("");
  const [sending, setSending] = useState(false);
  const [done, setDone] = useState(false);
  const [tos, setTos] = useState(false);
  const handleSubmit = async (ev) => {
    ev.preventDefault();
    if (!firstName || firstName.length < 2) {
      setError("Proszę podać imię");
      return;
    }
    if (!lastName || lastName.length < 2) {
      setError("Proszę podać nazwisko");
      return;
    }
    if (
      !email ||
      !email.includes("@") ||
      !email.includes(".") ||
      email.length < 5
    ) {
      setError("Proszę podać adres e-mail");
      return;
    }
    if (!phone) {
      setError("Proszę podać numer telefonu");
      return;
    }
    if (phone.length < 9) {
      setError("Proszę podać poprawny, 9-cyfrowy numer telefonu");
      return;
    }

    if (needsInvoice) {
      if (
        !companyName ||
        !taxNo ||
        !invoiceStreet ||
        !invoiceHouse ||
        !invoicePostal ||
        !invoiceCity
      ) {
        setError("Proszę podać dane do faktury VAT");
        return;
      }
    }

    if (!tos) {
      setError("Proszę zaakceptować regulamin oraz politykę prywatności");
      return;
    }
    setError("");
    setSending(true);

    const state = location.state;

    const calc = calculateRental(
      state.odbiorDate,
      state.zwrotDate,
      state.segment,
      state.car,
      state.odbiorOffice,
      state.zwrotOffice,
      state.options,
      state.insurance
    );

    const text = `<b>Imię i nazwisko:</b><br>
${firstName} ${lastName}<br><br>
<b>Adres e-mail:</b><br>
${email}<br><br>
<b>Numer telefonu:</b><br>
${phone}<br><br>
<b>Numer prawa jazdy:</b><br>
${prawoJazdy}<br><br>
<b>Seria i numer dowodu osobistego:</b><br>
${dowod}<br><br>
<b>Adres:</b><br>
${street} ${house}<br>
${postal} ${city}<br>
${country}<br><br>
<b>PESEL:</b><br>
${pesel}<br><br>
<b>Imiona rodziców:</b><br>
${parentNames}<br><br>
<b>Samochód:</b><br>
${state.car.name}<br><br>
<b>Data odbioru:</b><br>
${formatDate(state.odbiorDate)}<br><br>
<b>Data zwrotu:</b><br>
${formatDate(state.zwrotDate)}<br><br>
<b>Ilość dni:</b><br>
${calc.numDays} - ${calc.numDays === 1 ? "dzień" : "dni"} x cena dobowa = ${
      calc.periodPrice
    } zł<br><br>
    <b>Miejsce odbioru:</b><br>
${state.odbior}<br><br>
<b>Miejsce zwrotu:</b><br>
${state.zwrot}<br><br>
<b>Adres odbioru:</b><br>
${state.odbiorAddress || "nie dotyczy"}<br><br>
<b>Adres zwrotu:</b><br>
${state.zwrotAddress || "nie dotyczy"}<br><br>
${comments ? `Uwagi od klienta:<br>${comments}<br><br>` : ""}
<b>Ubezpieczenie:</b><br>
${state.insurance ? `Tak - ${calc.insuranceTotalCost} zł` : `Nie`}<br><br>
${state.insurance ? "" : `Kaucja:<br>${calc.depositCost} zł<br><br>`}
<b>Udogodnienia:</b><br>
${state.options.map((o) => `- ${o.name} - ${o.price} zł`).join("<br>")}<br><br>
${
  needsInvoice
    ? `Chcę otrzymać fakturę VAT<br><br>
${companyName}<br>
${taxNo}<br>
${invoiceStreet} ${invoiceHouse}<br>
${invoicePostal} ${invoiceCity}<br><br>`
    : ""
}
<b>Koszty:</b><br>
${calc.allPositions.map((o) => `- ${o.name} - ${o.value}`).join("<br>")}<br><br>
<b>Razem:</b><br>
${calc.sum} zł`;

    // console.log(text);
    // setSending(false);
    // return;
    try {
      await fetch(
        "https://us-central1-wypozyczalnia-5c467.cloudfunctions.net/makeReservation",
        {
          mode: "cors",
          method: "POST",

          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: email,
            message: text,
          }),
        }
      );
      setSending(false);
      setDone(true);
      if (window.pageYOffset > 220) {
        window.scrollTo({ top: 220, behavior: "smooth" });
      }
    } catch (err) {
      setSending(false);
      setError("Ups.. coś poszło nie tak");
    }
  };
  useEffect(() => {
    if (
      (typeof window !== "undefined" && !location.state) ||
      !location.state ||
      !location.state.car
    ) {
      window.location.href = "/";
    }
  });
  if (
    (typeof window !== "undefined" && !location.state) ||
    !location.state ||
    !location.state.car
  ) {
    return null;
  }
  return (
    <>
      <Breadcrumbs
        miniElement={{
          href: `/rezerwacja/udogodnienia`,
          label: "Udogodnienia",
          state: location.state,
        }}
        path={[
          {
            href: `/`,
            label: "Rezerwacja",
            state: location.state,
          },
          {
            href: `/rezerwacja/samochod`,
            label: "Wybór samochodu",
            state: location.state,
          },
          {
            href: `/rezerwacja/ubezpieczenie`,
            label: "Ubezpieczenie",
            state: location.state,
          },
          {
            href: `/rezerwacja/udogodnienia`,
            label: "Udogodnienia",
            state: location.state,
          },
          {
            href: `/rezerwacja/podsumowanie`,
            label: "Podsumowanie",
            state: location.state,
            current: true,
          },
        ]}
      />
      <Wrapper>
        <Options>
          <Header>Krok 5 - podsumowanie i dane kontaktowe</Header>
          {done ? (
            <>
              <ThankYou>Dziękujemy za złożenie rezerwacji.</ThankYou>
              <p style={{ textAlign: "center" }}>
                <Button href="/" color="secondary" variant="contained">
                  Powrót do strony głównej
                </Button>
              </p>
            </>
          ) : (
            <form onSubmit={handleSubmit}>
              <TwoGrid>
                <Label>
                  Imię *
                  <br />
                  <StyledTextField
                    fullWidth
                    variant="outlined"
                    type="text"
                    onChange={(ev) => {
                      setFirstName(ev.target.value);
                    }}
                    value={firstName}
                  />
                </Label>
                <Label>
                  Nazwisko *
                  <br />
                  <StyledTextField
                    fullWidth
                    variant="outlined"
                    type="text"
                    onChange={(ev) => {
                      setLastName(ev.target.value);
                    }}
                    value={lastName}
                  />
                </Label>
              </TwoGrid>
              <TwoGrid>
                <Label>
                  Adres e-mail *
                  <br />
                  <StyledTextField
                    fullWidth
                    variant="outlined"
                    type="email"
                    onChange={(ev) => {
                      setEmail(ev.target.value);
                    }}
                    value={email}
                  />
                </Label>
                <Label>
                  Numer telefonu *
                  <br />
                  <StyledTextField
                    fullWidth
                    variant="outlined"
                    type="tel"
                    onChange={(ev) => {
                      setPhone(ev.target.value);
                    }}
                    value={phone}
                  />
                </Label>
              </TwoGrid>

              <p>
                Podanie poniższych danych nie jest obowiązkowe, ale ułatwia
                proces tworzenia umowy.
              </p>

              <TwoGrid>
                <Label>
                  Numer prawa jazdy
                  <br />
                  <StyledTextField
                    fullWidth
                    variant="outlined"
                    type="text"
                    onChange={(ev) => {
                      setPrawoJazdy(ev.target.value);
                    }}
                    value={prawoJazdy}
                  />
                </Label>
                <Label>
                  Seria i numer dowodu osobistego
                  <br />
                  <StyledTextField
                    fullWidth
                    variant="outlined"
                    type="text"
                    onChange={(ev) => {
                      setDowod(ev.target.value);
                    }}
                    value={dowod}
                  />
                </Label>
              </TwoGrid>

              <TwoGrid>
                <Label>
                  Ulica
                  <br />
                  <StyledTextField
                    fullWidth
                    variant="outlined"
                    type="text"
                    onChange={(ev) => {
                      setStreet(ev.target.value);
                    }}
                    value={street}
                  />
                </Label>
                <Label>
                  Nr domu / nr mieszkania
                  <br />
                  <StyledTextField
                    fullWidth
                    variant="outlined"
                    type="text"
                    onChange={(ev) => {
                      setHouse(ev.target.value);
                    }}
                    value={house}
                  />
                </Label>
              </TwoGrid>

              <TwoGrid>
                <Label>
                  Kod pocztowy
                  <br />
                  <StyledTextField
                    fullWidth
                    variant="outlined"
                    type="text"
                    onChange={(ev) => {
                      setPostal(ev.target.value);
                    }}
                    value={postal}
                  />
                </Label>
                <Label>
                  Miejscowość
                  <br />
                  <StyledTextField
                    fullWidth
                    variant="outlined"
                    type="text"
                    onChange={(ev) => {
                      setCity(ev.target.value);
                    }}
                    value={city}
                  />
                </Label>
              </TwoGrid>

              <TwoGrid>
                <Label>
                  PESEL
                  <br />
                  <StyledTextField
                    fullWidth
                    variant="outlined"
                    type="text"
                    onChange={(ev) => {
                      setPesel(ev.target.value);
                    }}
                    value={pesel}
                  />
                </Label>
                <Label>
                  Imiona rodziców
                  <br />
                  <StyledTextField
                    fullWidth
                    variant="outlined"
                    type="text"
                    onChange={(ev) => {
                      setParentNames(ev.target.value);
                    }}
                    value={parentNames}
                  />
                </Label>
              </TwoGrid>

              <Label>
                Kraj
                <br />
                <StyledSelect
                  IconComponent={MapPin}
                  native
                  fullWidth
                  variant="outlined"
                  value={country}
                  onChange={(ev) => {
                    setCountry(ev.target.value);
                  }}
                >
                  {countries.map((c) => (
                    <option key={c}>{c}</option>
                  ))}
                </StyledSelect>
              </Label>

              <Label>
                Uwagi dodatkowe
                <br />
                <StyledTextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  multiline
                  onChange={(ev) => {
                    setComments(ev.target.value);
                  }}
                  value={comments}
                />
              </Label>

              <CheckboxLabel
                control={
                  <OptionCheckbox
                    checked={needsInvoice}
                    onChange={(ev) => {
                      const checked = ev.target.checked;
                      setNeedsInvoice(checked);
                    }}
                    color="primary"
                  />
                }
                label={<>Chcę otrzymać fakturę VAT</>}
              />

              {needsInvoice ? (
                <div style={{ marginTop: "1rem" }}>
                  <TwoGrid>
                    <Label>
                      Nazwa firmy *
                      <br />
                      <StyledTextField
                        fullWidth
                        variant="outlined"
                        type="text"
                        onChange={(ev) => {
                          setCompanyName(ev.target.value);
                        }}
                        value={companyName}
                      />
                    </Label>
                    <Label>
                      NIP *
                      <br />
                      <StyledTextField
                        fullWidth
                        variant="outlined"
                        type="text"
                        onChange={(ev) => {
                          setTaxNo(ev.target.value);
                        }}
                        value={taxNo}
                      />
                    </Label>
                  </TwoGrid>

                  <TwoGrid>
                    <Label>
                      Ulica *
                      <br />
                      <StyledTextField
                        fullWidth
                        variant="outlined"
                        type="text"
                        onChange={(ev) => {
                          setInvoiceStreet(ev.target.value);
                        }}
                        value={invoiceStreet}
                      />
                    </Label>
                    <Label>
                      Nr domu / nr mieszkania *
                      <br />
                      <StyledTextField
                        fullWidth
                        variant="outlined"
                        type="text"
                        onChange={(ev) => {
                          setInvoiceHouse(ev.target.value);
                        }}
                        value={invoiceHouse}
                      />
                    </Label>
                  </TwoGrid>

                  <TwoGrid>
                    <Label>
                      Kod pocztowy *
                      <br />
                      <StyledTextField
                        fullWidth
                        variant="outlined"
                        type="text"
                        onChange={(ev) => {
                          setInvoicePostal(ev.target.value);
                        }}
                        value={invoicePostal}
                      />
                    </Label>
                    <Label>
                      Miejscowość *
                      <br />
                      <StyledTextField
                        fullWidth
                        variant="outlined"
                        type="text"
                        onChange={(ev) => {
                          setInvoiceCity(ev.target.value);
                        }}
                        value={invoiceCity}
                      />
                    </Label>
                  </TwoGrid>
                </div>
              ) : null}

              <CheckboxLabel
                control={
                  <OptionCheckbox
                    checked={tos}
                    onChange={(ev) => {
                      const checked = ev.target.checked;
                      setTos(checked);
                    }}
                    color="primary"
                  />
                }
                label={
                  <>
                    Akceptuję{" "}
                    {/* <a href="/regulamin" target="_blank" rel="noopener">
                      regulamin
                    </a>{" "}
                    oraz{" "} */}
                    <a
                      href="/polityka-prywatnosci"
                      target="_blank"
                      rel="noopener"
                    >
                      politykę prywatności
                    </a>
                    .
                  </>
                }
              />
              {error ? <Error>{error}</Error> : null}
              {sending ? (
                <div style={{ textAlign: "center", margin: "1rem" }}>
                  <CircularProgress />
                </div>
              ) : (
                <NextButton
                  variant="contained"
                  color="primary"
                  size="large"
                  type="submit"
                >
                  Złóż rezerwację
                </NextButton>
              )}
            </form>
          )}
        </Options>
        <CarCard>
          <StandaloneCarCard
            hideButtons
            hidePrices
            noHoverEffect
            showCosts
            state={location.state}
          />
        </CarCard>
      </Wrapper>
    </>
  );
};
