export default [
  "Afganistan",
  "Albania",
  "Algieria",
  "Andora",
  "Angola",
  "Antigua i Barbuda",
  "Arabia Saudyjska",
  "Argentyna",
  "Armenia",
  "Australia",
  "Austria",
  "Azerbejdżan",
  "Bahamy",
  "Bahrajn",
  "Bangladesz",
  "Barbados",
  "Belgia",
  "Belize",
  "Benin",
  "Bhutan",
  "Białoruś",
  "Boliwia",
  "Bośnia i Hercegowina",
  "Botswana",
  "Brazylia",
  "Brunei",
  "Bułgaria",
  "Burkina Faso",
  "Burundi",
  "Chile",
  "Chiny",
  "Chorwacja",
  "Cypr",
  "Czad",
  "Czarnogóra",
  "Czechy",
  "Dania",
  "Demokratyczna Republika Konga",
  "Dominika",
  "Dominikana",
  "Dżibuti",
  "Egipt",
  "Ekwador",
  "Erytrea",
  "Estonia",
  "Eswatini",
  "Etiopia",
  "Fidżi",
  "Filipiny",
  "Finlandia",
  "Francja",
  "Gabon",
  "Gambia",
  "Ghana",
  "Grecja",
  "Grenada",
  "Gruzja",
  "Gujana",
  "Gwatemala",
  "Gwinea",
  "Gwinea Bissau",
  "Gwinea Równikowa",
  "Haiti",
  "Hiszpania",
  "Holandia",
  "Honduras",
  "Indie",
  "Indonezja",
  "Irak",
  "Iran",
  "Irlandia",
  "Islandia",
  "Izrael",
  "Jamajka",
  "Japonia",
  "Jemen",
  "Jordania",
  "Kambodża",
  "Kamerun",
  "Kanada",
  "Katar",
  "Kazachstan",
  "Kenia",
  "Kirgistan",
  "Kiribati",
  "Kolumbia",
  "Komory",
  "Kongo",
  "Korea Południowa",
  "Korea Północna",
  "Kostaryka",
  "Kuba",
  "Kuwejt",
  "Laos",
  "Lesotho",
  "Liban",
  "Liberia",
  "Libia",
  "Liechtenstein",
  "Litwa",
  "Luksemburg",
  "Łotwa",
  "Macedonia Północna",
  "Madagaskar",
  "Malawi",
  "Malediwy",
  "Malezja",
  "Mali",
  "Malta",
  "Maroko",
  "Mauretania",
  "Mauritius",
  "Meksyk",
  "Mikronezja",
  "Mjanma",
  "Mołdawia",
  "Monako",
  "Mongolia",
  "Mozambik",
  "Namibia",
  "Nauru",
  "Nepal",
  "Niemcy",
  "Niger",
  "Nigeria",
  "Nikaragua",
  "Norwegia",
  "Nowa Zelandia",
  "Oman",
  "Pakistan",
  "Palau",
  "Panama",
  "Papua-Nowa Gwinea",
  "Paragwaj",
  "Peru",
  "Polska",
  "Południowa Afryka",
  "Portugalia",
  "Republika Środkowoafrykańska",
  "Republika Zielonego Przylądka",
  "Rosja",
  "Rumunia",
  "Rwanda",
  "Saint Kitts i Nevis",
  "Saint Lucia",
  "Saint Vincent i Grenadyny",
  "Salwador",
  "Samoa",
  "San Marino",
  "Senegal",
  "Serbia",
  "Seszele",
  "Sierra Leone",
  "Singapur",
  "Słowacja",
  "Słowenia",
  "Somalia",
  "Sri Lanka",
  "Stany Zjednoczone",
  "Sudan",
  "Sudan Południowy",
  "Surinam",
  "Syria",
  "Szwajcaria",
  "Szwecja",
  "Tadżykistan",
  "Tajlandia",
  "Tanzania",
  "Timor Wschodni",
  "Togo",
  "Tonga",
  "Trynidad i Tobago",
  "Tunezja",
  "Turcja",
  "Turkmenistan",
  "Tuvalu",
  "Uganda",
  "Ukraina",
  "Urugwaj",
  "Uzbekistan",
  "Vanuatu",
  "Watykan",
  "Wenezuela",
  "Węgry",
  "Wielka Brytania",
  "Wietnam",
  "Włochy",
  "Wybrzeże Kości Słoniowej",
  "Wyspy Marshalla",
  "Wyspy Salomona",
  "Wyspy Świętego Tomasza i Książęca",
  "Zambia",
  "Zimbabwe",
  "Zjednoczone Emiraty Arabskie",
];
